<template>
   <div class="col-12 px-0 mb-1" ref="combo">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-sm-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ combo.nome }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ combo.id }}</p>
                     </div>
                     <div class="col d-none d-md-block" v-if="!combo.isVasilhame">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-store color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Loja(s):</strong>
                           <span class="text-capitalize d-none d-md-inline">{{ combo.lojas.length == 0 ? 'Sem lojas' : String(combo.lojas.map(l => String(l.nomeFantasia +' #'+ l.numeroLoja))).replace(/,/g, ", ").toLowerCase() }}</span>
                           <span class="text-capitalize d-inline d-md-none">{{ combo.lojas.length == 0 ? 'Sem lojas' : String(combo.lojas.map(l => String('Loja '+ l.numeroLoja))).replace(/,/g, ", ").toLowerCase() }}</span>
                        </p>
                     </div>
                     <div class="col d-none" :class="combo.isVasilhame ? 'd-md-block' : 'd-xl-block'">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far color-theme font-10 me-1" :class="combo.isVasilhame ? 'fa-wine-bottle' : 'fa-gift'"></i>
                           <strong class="subtitle me-1 d-none d-lg-inline"> {{ combo.isVasilhame ? 'Vasilhame' : 'Brinde' }}:</strong>
                           <span>{{ combo.brinde.nome == null || String(combo.brinde.nome).trim().length == 0 ? 'Não atribuído' : combo.brinde.nome }}</span>
                           <span v-if="combo.brinde.ean != null && String(combo.brinde.ean).trim().length > 0"> (Cód. {{ combo.brinde.ean }})</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme" v-if="combo.isVasilhame">
                     <span @click="buscarComprovantes"><i class="far fa-file-import"></i><small>Comprovantes</small></span>
                  </div>
                  <div class="btn-icone color-theme" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar vasilhames')">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar vasilhames')">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Combo',
   props: ['combo', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      buscarComprovantes : function () {
         this.$emit('buscarComprovantes', {'id': this.combo.id, 'nome': this.combo.nome})
      },
      editar : function () {
         if (!this.combo.isVasilhame && this.dadosUsuario.usuarioPermissoes.includes('Automação | Editar combos (packs)')) {
            this.$emit('editar', this.combo)
         } else if (this.combo.isVasilhame && this.dadosUsuario.usuarioPermissoes.includes('Automação | Editar vasilhames')) {
            this.$emit('editar', this.combo)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: ('Excluir '+ (this.combo.isVasilhame ? 'vasilhame' : 'combo/pack') +'?'),
            text: ('O '+ (this.combo.isVasilhame ? 'vasilhame' : 'combo/pack') +' será excluído, essa ação é irreversível.'),
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'automacao/deleteCombo',
                  params: {
                     id: this.combo.id
                  }
               }).then(() => {
                  this.$refs.combo.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Combo/Pack excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>