<template>
   <div class="col-12 px-0 mb-1" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-lg-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm text-center text-sm-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="mb-0 d-none d-sm-block">
                           <span class="font-10 limitador-1 text-secondary">
                              <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                              <span class="ms-3">
                                 <i class="far fa-tags font-9 me-1"></i>
                                 <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                              </span>
                           </span>
                        </p>
                     </div>
                     <div class="col mt-2" v-if="campos.selectEAN">
                        <v-select class="wpx-135 select-sm" :options="produto.codigos.filter(e => e.ean != null && /^[a-zA-Z0-9]+$/.test(e.ean))" v-model="produto.codigo" label="ean" :reduce="e => e.ean" :clearable="false" placeholder="-">
                           <template v-slot:no-options>Sem resultados</template>
                        </v-select>
                     </div>
                     <div class="col mt-md-2 d-none d-md-block" v-else-if="produto.ean || produto.codigos">
                        <p class="font-12 mb-0 limitador-1" v-if="produto.ean">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código:</strong><span> {{ produto.ean }}</span>
                        </p>
                        <p class="font-12 mb-0 limitador-1" v-else-if="produto.codigos">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigos.filter(e => e.ean != null).length == 0 ? 'Sem código' : String(produto.codigos.filter(e => e.ean != null).map(e => e.ean)).replace(/,/g, ", ") }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-115 text-center mx-auto mx-sm-0 mt-2 mt-sm-0" v-if="campos.preco">
                  <label><i class="far fa-dollar-sign color-theme font-11 me-1"></i> Preço</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.preco" />
               </div>
               <div class="wpx-115 text-center mx-auto mx-sm-0 mt-2 mt-sm-0 font-15" v-else-if="produto.preco">
                  <span class="d-block lh-1"><small>R$ </small>{{ produto.preco == null ? '0,00' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
               </div>
               <div class="wpx-115 text-center mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <label><i class="far fa-box color-theme font-11 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.quantidade" />
               </div>
               <div class="w-max-content text-center font-16 mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'campos'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'produto.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.produto.preco = valor
         })
      },
      'produto.quantidade': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 3}).then((valor) => {
            this.produto.quantidade = valor
         })
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      select : function () {
         if (!this.produto.preco) this.produto.preco = 0

         this.produto.quantidade = 1
         this.$emit('select', this.produto)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   },
   mounted() {
      let codigo = this.produto.codigos ? this.produto.codigos.filter(e => e.ean != null && /^[a-zA-Z0-9]+$/.test(e.ean))[0] : null
      this.produto.codigo = codigo != null ? codigo.ean : null
   }
}

</script>